import styled from "@emotion/styled";

const FullOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
`;

export default FullOverlay;
