import styled from "@emotion/styled";

export const RadioBox = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: #254059;
  font-size: 24px;
  line-height: 48px;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  transition: all 0.15s ease-out;

  .check-icon {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%) scale(0);
    margin-bottom: 36px;
    transition: transform 0.1s 0.1s ease-out;
  }

  &.active-style {
    font-weight: 600;
    background: white;
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.04);
    opacity: 0;
    transition: all 0.15s ease-out;
    border-radius: 4px;
  }

  &.active.active-style {
    opacity: 1;
    transform: scale(1.06);
    .check-icon {
      transform: translate(-50%) scale(1);
    }
  }

  &.disabled-style {
    opacity: 0.3;
    color: #505050;
  }
`;

export const RadioBoxGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0px;
  border-radius: 4px;
  border: 1px solid #dedede;
`;
