/** @jsxImportSource theme-ui */
import { useState } from "react";
import { Box, Image } from "theme-ui";
import TabNavigation from "../../../components/TabNavigation";
import { PreloadScenarioImages } from "../../../components";
import { Switch, Route, useLocation, Link, useHistory } from "react-router-dom";
import { AnimatePresence } from "framer-motion/dist/framer-motion";
import MotionScreen from "../MotionScreen";

import menuImage from "../../../images/Support-Scenario-Menu-A.png";
import homeImage from "../../../images/scenario-a-home.png";
import homeTrackingImage from "../../../images/scenario-a-home-tracking.png";
import homeCheckinImage from "../../../images/scenario-a-home-checkin.png";
import progressImage from "../../../images/scenario-a-progress.png";
import progressExpandedImage from "../../../images/scenario-a-progress-expanded.png";
import planImage from "../../../images/scenario-a-plan.png";
import supportImage from "../../../images/scenario-a-support.png";
import supportCommunityImage from "../../../images/scenario-a-support-community.png";

import { useDoubleTap } from "use-double-tap";
import { useSwipeable } from "react-swipeable";

const Home = () => {
  const [showTracking, setShowTracking] = useState(false);
  const [showCheckin, setShowCheckin] = useState(false);

  const trackingHandlers = useSwipeable({
    onSwipedRight: () => {
      setShowTracking(false);
    },
  });
  const checkinHandlers = useSwipeable({
    onSwipedRight: () => {
      setShowCheckin(false);
    },
  });
  return (
    <MotionScreen>
      <Box sx={{ position: "relative" }}>
        <Image
          src={homeImage}
          sx={{ width: "100%", height: "auto", verticalAlign: "bottom" }}
        />
        <Box
          sx={{
            position: "absolute",
            top: "12.3%",
            left: "9%",
            width: "45vw",
            height: "13vw",
            background: "rgba(255,0,0,0.0)",
          }}
          onClick={() => setShowTracking(true)}
        />
        <Box
          sx={{
            position: "absolute",
            top: "15%",
            left: "9%",
            width: "70vw",
            height: "25vw",
            background: "rgba(255,0,0,0.0)",
          }}
          onClick={() => setShowCheckin(true)}
        />
      </Box>
      <AnimatePresence>
        {showTracking && (
          <MotionScreen
            key="a-tracking"
            style={{ position: "fixed", zIndex: 10000 }}
            initial={{ x: "100%" }}
            animate={{ x: "0%" }}
            exit={{ x: "100%" }}
            transition={{ ease: "easeInOut", duration: 0.2 }}
          >
            <Image
              src={homeTrackingImage}
              {...trackingHandlers}
              sx={{ width: "100%", height: "auto", verticalAlign: "bottom" }}
            />
            <Box
              sx={{
                position: "absolute",
                top: "7%",
                left: "9%",
                width: "10vw",
                height: "10vw",
                background: "rgba(255,0,0,0.0)",
              }}
              onClick={() => setShowTracking(false)}
            />
          </MotionScreen>
        )}
        {showCheckin && (
          <MotionScreen
            key="a-checkin"
            initial={{ x: "100%" }}
            animate={{ x: "0%" }}
            exit={{ x: "100%" }}
            style={{ position: "fixed", zIndex: 10000 }}
            transition={{ ease: "easeInOut", duration: 0.2 }}
          >
            <Image
              src={homeCheckinImage}
              {...checkinHandlers}
              sx={{ width: "100%", height: "auto", verticalAlign: "bottom" }}
            />
            <Box
              sx={{
                position: "absolute",
                top: "7%",
                left: "9%",
                width: "10vw",
                height: "10vw",
                background: "rgba(255,0,0,0.0)",
              }}
              onClick={() => setShowCheckin(false)}
            />
          </MotionScreen>
        )}
      </AnimatePresence>
    </MotionScreen>
  );
};
const Progress = () => {
  const [showExpanded, setShowExpanded] = useState(false);
  return (
    <MotionScreen>
      <Box sx={{ position: "relative" }}>
        <Image
          src={progressImage}
          sx={{ width: "100%", height: "auto", verticalAlign: "bottom" }}
        />
        <Box
          sx={{
            position: "absolute",
            top: "9.5%",
            left: "4%",
            width: "28vw",
            height: "15vw",
            background: "rgba(255,0,0,0.0)",
          }}
          onClick={() => setShowExpanded(true)}
        />
      </Box>
      <AnimatePresence>
        {showExpanded && (
          <MotionScreen
            key="a-progress-expanded"
            style={{ position: "fixed", zIndex: 10000 }}
            transition={{ ease: "easeInOut", duration: 0.2 }}
          >
            <Image
              src={progressExpandedImage}
              sx={{ width: "100%", height: "auto", verticalAlign: "bottom" }}
            />
            <Box
              sx={{
                position: "absolute",
                top: "8%",
                left: "9%",
                width: "15vw",
                height: "15vw",
                background: "rgba(255,0,0,0.0)",
              }}
              onClick={() => setShowExpanded(false)}
            />
          </MotionScreen>
        )}
      </AnimatePresence>
    </MotionScreen>
  );
};
const Plan = () => (
  <MotionScreen>
    <Box>
      <Image
        src={planImage}
        sx={{ width: "100%", height: "auto", verticalAlign: "bottom" }}
      />
    </Box>
  </MotionScreen>
);

const Support = () => {
  const [showCommunity, setShowCommunity] = useState(false);
  return (
    <MotionScreen>
      <Box sx={{ position: "relative" }}>
        <Image
          src={supportImage}
          sx={{ width: "100%", height: "auto", verticalAlign: "bottom" }}
        />
        <Box
          sx={{
            position: "absolute",
            top: "6%",
            left: "32%",
            width: "25vw",
            height: "13vw",
            background: "rgba(255,0,0,0.0)",
          }}
          onClick={() => setShowCommunity(true)}
        />
      </Box>
      <AnimatePresence>
        {showCommunity && (
          <MotionScreen key="a-support-community">
            <Image
              src={supportCommunityImage}
              sx={{ width: "100%", height: "auto", verticalAlign: "bottom" }}
            />
            <Box
              sx={{
                position: "absolute",
                top: "15%",
                left: "5%",
                width: "22vw",
                height: "13vw",
                background: "rgba(255,0,0,0.0)",
              }}
              onClick={() => setShowCommunity(false)}
            />
          </MotionScreen>
        )}
      </AnimatePresence>
    </MotionScreen>
  );
};

export const ScenarioAPages = () => {
  const location = useLocation();
  const history = useHistory();

  const plusIconEvents = useDoubleTap(() => {
    history.push("/support/b");
    window.ReactNativeWebView?.postMessage("PLUS_ICON_A");
  });
  return (
    <MotionScreen>
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.key}>
          <Route path="/support/a/home" component={Home} exact />
          <Route path="/support/a/progress" component={Progress} exact />
          <Route path="/support/a/plan" component={Plan} exact />
          <Route path="/support/a/support" component={Support} exact />
        </Switch>
        <TabNavigation
          key="tab-nav-a"
          rootPath="/support/a"
          plusIconEvents={plusIconEvents}
        />
      </AnimatePresence>
    </MotionScreen>
  );
};

export const ScenarioAMenu = () => {
  return (
    <MotionScreen>
      <Box>
        <Link to="/support/a/home">
          <Image
            src={menuImage}
            sx={{ width: "100%", height: "auto", verticalAlign: "bottom" }}
          />
        </Link>
      </Box>
    </MotionScreen>
  );
};

const ScenarioA = () => {
  const location = useLocation();
  return (
    <>
      <PreloadScenarioImages />
      <AnimatePresence>
        <Switch location={location} key={location.key}>
          <Route path="/support/a" exact component={ScenarioAMenu} />
          <Route path="/support/a" component={ScenarioAPages} />
        </Switch>
      </AnimatePresence>
    </>
  );
};

export default ScenarioA;
