import useWindowSize from "../hooks/useWindowSize";

const ConstrainedView = ({ viewWidth, children }) => {
  const { width } = useWindowSize();
  if (!viewWidth) return children;
  return width > viewWidth ? <Message viewWidth={viewWidth} /> : children;
};

const Message = ({ viewWidth }) => (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-white">
    <div className="m-auto w-1/2 text-balance text-center text-lg font-medium">
      {`Please use mobile device, mobile emulator, or set your browser window to
      ${viewWidth}px or less.`}
    </div>
  </div>
);

export default ConstrainedView;
