import styled from "@emotion/styled";
import { CSSTransition, SwitchTransition } from "react-transition-group";

const FadeOutIn = ({
  children,
  index,
  durationIn = "0.5s",
  durationOut = "0.5s",
}) => {
  return (
    <Container durationIn={durationIn} durationOut={durationOut}>
      <SwitchTransition>
        <CSSTransition
          appear={true}
          key={index}
          addEndListener={(node, done) =>
            node.addEventListener("transitionend", done, false)
          }
          classNames="fade-out-in"
          unmountOnExit
        >
          {children}
        </CSSTransition>
      </SwitchTransition>
    </Container>
  );
};

const Container = styled.div`
  .fade-out-in {
    &-appear {
      opacity: 0;
    }
    &-appear-active {
      opacity: 1;
    }
    &-enter {
      opacity: 0;
    }
    &-enter-active {
      opacity: 1;
    }
    &-exit {
      opacity: 1;
    }
    &-exit-active {
      opacity: 0;
    }
    &-appear-active,
    &-enter-active {
      transition: opacity ${({ durationIn }) => durationIn} linear;
    }
    &-exit-active {
      transition: opacity ${({ durationOut }) => durationOut} linear;
    }
  }
`;

export default FadeOutIn;
