import { Amplify } from "aws-amplify";
import useAuth from "./hooks/useAuth";
import awsconfig from "./aws-exports";
import App from "./App";

awsconfig.oauth = {
  ...awsconfig.oauth,
  scope: ["openid", "profile", "email"],
  redirectSignIn: window.location.origin,
  responseType: "token",
};

Amplify.configure(awsconfig);

function AppWithAuth() {
  const { isSignedIn } = useAuth();
  return isSignedIn && <App />;
}

export default AppWithAuth;
