import { createContext, useReducer } from "react";
import { testResultsB, testResultsA } from "../data/testData";
const AppContext = createContext();

const _initialState = {
  settings: {
    weight: 251,
    height: 5.5,
    pulse: 82,
    age: 37,
    dose: 30,
  },
  isLoading: false,
  datasets: {
    test: [...testResultsA],
    testB: [...testResultsB],
  },
  showEditSheet: false,
};

function fetchDataset(settings) {
  const params = new URLSearchParams(settings).toString();

  return fetch(`/api?${params}`).then((res) => res.json());
}

function AppReducer(state, action) {
  switch (action.type) {
    case "UPDATE_SETTING": {
      return {
        ...state,
        settings: {
          ...state.settings,
          [action.payload.id]: action.payload.value,
        },
      };
    }
    case "ADD_DATASET": {
      return {
        ...state,
        datasets: {
          ...state.datasets,
          [action.payload.id]: action.payload.data,
        },
      };
    }
    case "SET_SHOW_EDIT": {
      return {
        ...state,
        showEditSheet: action.payload,
      };
    }
    case "SET_IS_LOADING": {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    default:
      return state;
  }
}

const AppContextProvider = ({ children, initialState = _initialState }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  function addDataset(id, data) {
    return dispatch({ type: "ADD_DATASET", payload: { id, data } });
  }

  function getDataset(id = "current") {
    dispatch({ type: "SET_IS_LOADING", payload: true });
    fetchDataset(state.settings)
      .then((data) => {
        addDataset(id, data);
        dispatch({ type: "SET_IS_LOADING", payload: false });
        return;
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  }

  function updateSetting(id, value) {
    dispatch({ type: "UPDATE_SETTING", payload: { id, value } });
  }

  function setShowEditSheet(show) {
    dispatch({ type: "SET_SHOW_EDIT", payload: show });
  }

  return (
    <AppContext.Provider
      value={{
        getDataset,
        addDataset,
        updateSetting,
        setShowEditSheet,
        state,
        dispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
