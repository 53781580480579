export const testResultsA = [
  [247.842, 247.842, 247.842],
  [247.55207037505957, 247.6042055278442, 247.63436206660776],
  [247.2667215840237, 247.3695261093768, 247.4291611215597],
  [246.98588125057262, 247.13792093810062, 247.22636856260524],
  [246.70947814191882, 246.90934974207417, 247.0259561231905],
  [246.43744215073954, 246.6837727769094, 246.8278958685182],
  [246.1697042773946, 246.46115081886063, 246.63216019165398],
  [245.9061966124253, 246.2414451580044, 246.43872180967833],
  [245.64685231932964, 246.0246175915085, 246.24755375988374],
  [245.39160561760988, 245.8106304169891, 246.05862939601644],
  [245.1403917660879, 245.59944642595516, 245.87192238456228],
  [244.893147046484, 245.39102889733832, 245.68740670107627],
  [244.64980874725546, 245.1853415911079, 245.505056626555],
  [244.41031514769028, 244.98234874196956, 245.32484674385174],
  [244.17460550225206, 244.782015053146, 245.14675193413382],
  [243.94262002517254, 244.58430569023986, 244.97074737338127],
  [243.71429987528754, 244.3891862751764, 244.79680852892662],
  [243.48958714111217, 244.19662288022593, 244.62491115603552],
  [243.2684248261522, 244.00658202210428, 244.45503129452723],
  [243.05075683444738, 243.81903065615074, 244.28714526543504],
  [242.83652795634313, 243.6339361705821, 244.12122966770553],
  [242.6256838544872, 243.4512663808223, 243.957261374937],
  [242.41817105004714, 243.27098952390568, 243.79521753215582],
  [242.2139369091463, 243.09307425295447, 243.63507555263078],
  [242.01292962951325, 242.9174896317277, 243.47681311472485],
  [241.8150982273429, 242.74420512924226, 243.32040815878375],
  [241.6203925243647, 242.57319061446395, 243.16583888406126],
  [241.42876313511542, 242.40441635106833, 243.01308374568035],
  [241.24016145441286, 242.23785299227004, 242.8621214516303],
  [241.05453964502775, 242.07347157571996, 242.7129309597986],
  [240.8718506255499, 241.9112435184692, 242.56549147503827],
  [240.69204805844691, 241.75114061199895, 242.41978244626915],
  [240.5150863383104, 241.59313501731575, 242.2757835636132],
  [240.34092058028915, 241.43719926011042, 242.13347475556392],
  [240.16950660870404, 241.28330622598125, 241.9928361861884],
  [240.00080094584362, 241.13142915571885, 241.85384825236247],
  [239.834760800936, 240.98154164065355, 241.7164915810384],
  [239.6713440592956, 240.8336176180632, 241.5807470265446],
  [239.5105092716408, 240.68763136664134, 241.44659566791668],
  [239.3522156435812, 240.54355750202495, 241.31401880626044],
  [239.19642302526987, 240.4013709723803, 241.1829979621453],
  [239.04309190122007, 240.26104705404703, 241.05351487302863],
  [238.89218338028243, 240.12256134723913, 240.9255514907102],
  [238.74365918578033, 239.98588977180222, 240.79908997881637],
  [238.59748164580165, 239.85100856302654, 240.67411271031412],
  [238.4536136836433, 239.7178942675146, 240.55060226505384],
  [238.3120188084073, 239.58652373910317, 240.42854142734151],
  [238.17266110574488, 239.45687413483844, 240.30791318353877],
  [238.0355052287474, 239.3289229110041, 240.18870071969167],
  [237.90051638898078, 239.2026478192015, 240.07088741918687],
  [237.76766034766158, 239.07802690248093, 239.95445686043558],
  [237.63690340697286, 238.95503849152374, 239.83939281458467],
  [237.5082124015169, 238.8336612008744, 239.72567924325438],
  [237.38155468990288, 238.7138739252221, 239.6133002963031],
  [237.2568981464681, 238.59565583573067, 239.50224030961778],
  [237.1342111531292, 238.47898637641714, 239.39248380293068],
  [237.01346259136264, 238.36384526057716, 239.2840154776617],
  [236.89462183431172, 238.25021246725763, 239.1768202147859],
  [236.77765873901842, 238.13806823777537, 239.07088307272596],
  [236.66254363877775, 238.0273930722816, 238.96618928526985],
  [236.5492473356132, 237.91816772637088, 238.86272425951245],
  [236.43774109287074, 237.8103732077354, 238.76047357382126],
  [236.32799662793016, 237.70399077286214, 238.65942297582674],
  [236.21998610503135, 237.5990019237738, 238.55955838043516],
  [236.11368212821407, 237.49538840481253, 238.4608658678658],
  [236.00905773436898, 237.39313219946536, 238.36333168171038],
  [235.90608638639898, 237.29221552723155, 238.2669422270158],
  [235.80474196648814, 237.192620840531, 238.17168406838908],
  [235.70499876947702, 237.09433082165282, 238.07754392812478],
  [235.60683149634298, 236.99732837974423, 237.98450868435407],
  [235.51021524778324, 236.90159664783874, 237.89256536921582],
  [235.41512551789927, 236.8071189799234, 237.8017011670492],
  [235.32153818798125, 236.71387894804406, 237.71190341260703],
  [235.2294295203904, 236.62186033944926, 237.6231595892907],
  [235.1387761525382, 236.53104715377077, 237.5354573274054],
  [235.0495550909607, 236.44142360024162, 237.44878440243596],
  [234.96174370548638, 236.3529740949503, 237.3631287333429],
  [234.87531972349618, 236.26568325813093, 237.27847838087854],
  [234.79026122427427, 236.17953591148915, 237.19482154592282],
  [234.706546633448, 236.09451707556292, 237.11214656783866],
  [234.6241547175158, 236.01061196711754, 237.0304419228466],
  [234.5430645784616, 235.92780599657544, 236.94969622241854],
  [234.4632556484538, 235.84608476547928, 236.8698982116904],
  [234.3847076846289, 235.76543406398812, 236.79103676789325],
  [234.3074007639568, 235.68583986840676, 236.71310089880294],
  [234.23131527818754, 235.6072883387473, 236.6360797412081],
  [234.15643192887788, 235.52976581632245, 236.55996255939573],
  [234.0827317224964, 235.45325882137064, 236.484738743655],
  [234.01019596560582, 235.3777540507122, 236.41039780879808],
  [233.9388062601218, 235.30323837543605, 236.33692939269912],
  [233.86854449864623, 235.2296988386168, 236.26432325484947],
  [233.7993928598745, 235.15712265306195, 236.19256927493055],
  [233.7313338040753, 235.08549719908828, 236.12165745140302],
  [233.6643500686418, 235.01481002232757, 236.05157790011293],
  [233.59842466371313, 234.94504883156094, 235.98232085291386],
  [233.53354086786504, 234.8762014965818, 235.91387665630526],
  [233.46968222386872, 234.80825604608646, 235.84623577008722],
  [233.4068325345164, 234.74120066559257, 235.77938876603034],
  [233.3449758585132, 234.67502369538485, 235.71332632656174],
  [233.2840965064338, 234.60971362848767, 235.64803924346637],
  [233.22417903674275, 234.54525910866417, 235.58351841660328],
  [233.16520825187806, 234.4816489284415, 235.5197548526375],
  [233.1071691943963, 234.41887202716234, 235.45673966378612],
  [233.05004714317897, 234.3569174890614, 235.39446406657984],
  [232.99382760969834, 234.2957745413674, 235.33291938063834],
  [232.9384963343429, 234.23543255243, 235.2720970274606],
  [232.8840392828001, 234.175881029871, 235.21198852922896],
  [232.83044264249713, 234.11710961875994, 235.15258550762772],
  [232.77769281909715, 234.05910809981378, 235.09387968267484],
  [232.7257764330513, 234.0018663876196, 235.03586287156838],
  [232.67468031620507, 233.94537452888116, 234.97852698754554],
  [232.62439150845836, 233.88962270068816, 234.92186403875556],
  [232.57489725447832, 233.8346012088082, 234.86586612714592],
  [232.52618500046378, 233.78030048600112, 234.81052544736121],
  [232.47824239096147, 233.72671109035534, 234.75583428565537],
  [232.43105726573188, 233.67382370364635, 234.7017850188163],
  [232.38461765666497, 233.62162912971618, 234.64837011310362],
  [232.3389117847447, 233.57011829287447, 234.5955821231982],
  [232.29392805706127, 233.5192822363203, 234.54341369116452],
  [232.24965506387062, 233.46911212058487, 234.49185754542518],
  [232.20608157570058, 233.41959922199433, 234.44090649974717],
  [232.16319654050247, 233.37073493115312, 234.3905534522404],
  [232.120989080848, 233.3225107514467, 234.34079138436755],
  [232.0794484911702, 233.2749182975643, 234.29161335996596],
  [232.038564235048, 233.2279492940408, 234.2430125242808],
  [231.99832594253385, 233.1815955738178, 234.19498210300958],
  [231.95872340752345, 233.1358490768235, 234.14751540135782],
  [231.91974658516702, 233.09070184857129, 234.10060580310605],
  [231.8813855893215, 233.04614603877636, 234.05424676968752],
  [231.8436306900431, 233.00217389999116, 234.00843183927677],
  [231.8064723111193, 232.95877778625754, 233.96315462588896],
  [231.76990102764, 232.91595015177805, 233.91840881848984],
  [231.73390756360678, 232.8736835496032, 233.874188180116],
  [231.69848278958037, 232.831970630337, 233.8304865470054],
  [231.66361772036498, 232.79080414085885, 233.78729782773848],
  [231.62930351272908, 232.75017692306238, 233.74461600238897],
  [231.59553146316267, 232.71008191261072, 233.70243512168466],
  [231.56229300566957, 232.67051213770839, 233.66074930617847],
  [231.52957970959474, 232.63146071788867, 233.6195527454286],
  [231.49738327748582, 232.59292086281746, 233.5788396971888],
  [231.46569554298873, 232.55488587111248, 233.53860448660805],
  [231.4345084687763, 232.51734912917817, 233.4988415054394],
  [231.40381414450957, 232.48030411005533, 233.45954521125824],
  [231.37360478483137, 232.4437443722867, 233.42071012669004],
  [231.3438727273919, 232.4076635587964, 233.38233083864657],
  [231.3146104309049, 232.37205539578508, 233.34440199757145],
  [231.2858104732352, 232.3369136916385, 233.30691831669466],
  [231.25746554951576, 232.30223233585124, 233.26987457129545],
  [231.2295684702952, 232.2680052979642, 233.23326559797417],
  [231.20211215971412, 232.23422662651583, 233.19708629393264],
  [231.1750896537104, 232.2008904480074, 233.16133161626257],
  [231.14849409825277, 232.16799096588167, 233.12599658124316],
  [231.1223187476023, 232.13552245951504, 233.09107626364587],
  [231.09655696260162, 232.10347928322275, 233.0565657960484],
  [231.0712022089907, 232.0718558652772, 233.02246036815595],
  [231.0462480557497, 232.0406467069392, 232.9887552261307],
  [231.02168817346745, 232.0098463815018, 232.9554456719295],
  [230.99751633273664, 231.9794495333466, 232.9225270626487],
  [230.97372640257316, 231.9494508770128, 232.8899948098771],
  [230.95031234886144, 231.9198451962776, 232.85784437905642],
  [230.9272682328238, 231.89062734324986, 232.8260712888492],
  [230.90458820951406, 231.8617922374745, 232.79467111051423],
  [230.88226652633512, 231.83333486504918, 232.7636394672892],
  [230.86029752157992, 231.80525027775275, 232.7329720337806],
  [230.83867562299525, 231.7775335921844, 232.7026645353609],
  [230.81739534636844, 231.75017998891497, 232.67271274757252],
  [230.7964512941364, 231.7231847116486, 232.6431124955394],
  [230.77583815401653, 231.69654306639583, 232.61385965338465],
  [230.75555069765923, 231.67025042065748, 232.58495014365576],
];

export const testResultsB = [
  [200.0, 200.0, 200.0],
  [199.79159245905765, 199.8841058112637, 199.97473615425062],
  [199.58349409983393, 199.76836898504538, 199.94950401698074],
  [199.37570446364424, 199.65278930767636, 199.92430354839323],
  [199.16822309248445, 199.53736656577797, 199.89913470874097],
  [198.96104952902988, 199.42210054626145, 199.87399745832667],
  [198.7541833166343, 199.3069910363272, 199.8488917575029],
  [198.54762399932892, 199.19203782346463, 199.82381756667203],
  [198.34137112182145, 199.0772406954517, 199.79877484628602],
  [198.13542422949507, 198.9625994403546, 199.77376355684657],
  [197.92978286840727, 198.84811384652716, 199.74878365890493],
  [197.72444658528917, 198.73378370261068, 199.7238351130618],
  [197.51941492754412, 198.61960879753335, 199.6989178799674],
  [197.3146874432471, 198.50558892051006, 199.6740319203213],
  [197.1102636811435, 198.39172386104184, 199.64917719487246],
  [196.90614319064804, 198.2780134089156, 199.624353664419],
  [196.7023255218441, 198.1644573542036, 199.5995612898083],
  [196.49881022548237, 198.05105548726317, 199.5748000319369],
  [196.2955968529801, 197.93780759873633, 199.55006985175038],
  [196.09268495642004, 197.82471347954933, 199.52537071024332],
  [195.89007408854943, 197.71177292091235, 199.50070256845936],
  [195.68776380277893, 197.59898571431899, 199.47606538749088],
  [195.48575365318192, 197.48635165154607, 199.45145912847923],
  [195.28404319449317, 197.373870524653, 199.42688375261446],
  [195.08263198210813, 197.2615421259817, 199.40233922113532],
  [194.8815195720818, 197.14936624815584, 199.37782549532926],
  [194.68070552112775, 197.0373426840809, 199.3533425365323],
  [194.48018938661727, 196.92547122694336, 199.32889030612895],
  [194.2799707265782, 196.81375167021068, 199.30446876555223],
  [194.08004909969418, 196.70218380763063, 199.28007787628354],
  [193.88042406530352, 196.59076743323106, 199.25571759985263],
  [193.6810951833982, 196.47950234131952, 199.23138789783758],
  [193.48206201462307, 196.36838832648283, 199.20708873186456],
  [193.28332412027473, 196.25742518358672, 199.1828200636081],
  [193.0848810623007, 196.14661270777546, 199.15858185479064],
  [192.88673240329817, 196.0359506944715, 199.1343740671828],
  [192.68887770651344, 195.92543893937503, 199.1101966626031],
  [192.4913165358407, 195.8150772384637, 199.08604960291802],
  [192.29404845582107, 195.70486538799204, 199.0619328500419],
  [192.09707303164174, 195.59480318449138, 199.03784636593684],
  [191.90038982913492, 195.4848904247693, 199.01379011261275],
  [191.70399841477698, 195.3751269059092, 198.98976405212719],
  [191.50789835568742, 195.26551242527012, 198.96576814658528],
  [191.31208921962792, 195.1560467804861, 198.9418023581398],
  [191.11657057500145, 195.04672976946605, 198.917866648991],
  [190.92134199085115, 194.93756119039327, 198.89396098138653],
  [190.7264030368597, 194.82854084172513, 198.87008531762146],
  [190.53175328334802, 194.71966852219253, 198.8462396200382],
  [190.33739230127452, 194.6109440307998, 198.82242385102637],
  [190.14331966223412, 194.5023671668241, 198.79863797302284],
  [189.94953493845733, 194.39393772981512, 198.77488194851162],
  [189.7560377028092, 194.28565551959483, 198.7511557400238],
  [189.5628275287885, 194.17752033625686, 198.72745931013745],
  [189.36990399052675, 194.06953198016637, 198.70379262147767],
  [189.17726666278725, 193.96169025195962, 198.6801556367165],
  [188.9849151209641, 193.85399495254345, 198.6565483185727],
  [188.79284894108142, 193.7464458830951, 198.632970629812],
  [188.60106769979222, 193.6390428450618, 198.60942253324666],
  [188.4095709743777, 193.5317856401603, 198.5859039917358],
  [188.21835834274597, 193.42467407037668, 198.56241496818504],
  [188.02742938343155, 193.31770793796576, 198.5389554255466],
  [187.83678367559412, 193.21088704545102, 198.51552532681913],
  [187.64642079901765, 193.10421119562392, 198.49212463504787],
  [187.4563403341096, 192.99768019154376, 198.46875331332433],
  [187.2665418618999, 192.8912938365373, 198.4454113247863],
  [187.07702496404002, 192.7850519341983, 198.42209863261797],
  [186.88778922280204, 192.67895428838713, 198.39881520004965],
  [186.69883422107785, 192.5730007032306, 198.37556099035783],
  [186.51015954237803, 192.4671909831214, 198.352335966865],
  [186.32176477083112, 192.36152493271788, 198.32914009293987],
  [186.13364949118258, 192.25600235694355, 198.30597333199697],
  [185.94581328879394, 192.15062306098685, 198.28283564749677],
  [185.75825574964188, 192.04538685030076, 198.25972700294568],
  [185.57097646031718, 191.94029353060233, 198.2366473618958],
  [185.38397500802418, 191.83534290787253, 198.21359668794508],
  [185.19725098057935, 191.73053478835564, 198.19057494473708],
  [185.01080396641083, 191.62586897855917, 198.16758209596105],
  [184.82463355455727, 191.5213452852532, 198.1446181053518],
  [184.638739334667, 191.4169635154703, 198.12168293668958],
  [184.45312089699718, 191.31272347650494, 198.09877655380023],
  [184.26777783241278, 191.2086249759134, 198.0758989205549],
  [184.08270973238575, 191.1046678215132, 198.0530500008701],
  [183.89791618899412, 191.00085182138272, 198.0302297587076],
  [183.71339679492112, 190.897176783861, 198.00743815807454],
  [183.52915114345421, 190.79364251754737, 197.98467516302304],
  [183.34517882848422, 190.69024883130098, 197.96194073765048],
  [183.16147944450452, 190.5869955342405, 197.9392348460992],
  [182.97805258660998, 190.48388243574382, 197.91655745255667],
  [182.79489785049626, 190.3809093454477, 197.89390852125518],
  [182.61201483245873, 190.27807607324726, 197.871288016472],
  [182.42940312939174, 190.17538242929587, 197.8486959025292],
  [182.24706233878766, 190.07282822400458, 197.8261321437936],
  [182.06499205873595, 189.9704132680419, 197.80359670467678],
  [181.88319188792238, 189.8681373723335, 197.78108954963497],
  [181.70166142562803, 189.76600034806162, 197.75861064316908],
  [181.5204002717285, 189.664002006665, 197.73615994982447],
  [181.339408026693, 189.56214215983834, 197.71373743419105],
  [181.15868429158346, 189.46042061953207, 197.6913430609032],
  [180.97822866805362, 189.35883719795197, 197.6689767946396],
  [180.7980407583482, 189.25739170755872, 197.64663860012334],
  [180.618120165302, 189.15608396106776, 197.6243284421218],
  [180.4384664923391, 189.05491377144872, 197.60204628544648],
  [180.2590793434718, 188.95388095192524, 197.57979209495312],
  [180.07995832329996, 188.8529853159746, 197.5575658355416],
  [179.90110303700993, 188.75222667732726, 197.5353674721558],
  [179.72251309037398, 188.65160484996665, 197.51319696978356],
  [179.544188089749, 188.55111964812878, 197.49105429345676],
  [179.36612764207604, 188.45077088630183, 197.4689394082511],
  [179.18833135487918, 188.350558379226, 197.44685227928616],
  [179.01079883626474, 188.25048194189287, 197.42479287172526],
  [178.83352969492051, 188.15054138954537, 197.4027611507754],
  [178.6565235401148, 188.05073653767724, 197.38075708168742],
  [178.4797799816955, 187.9510672020327, 197.35878062975559],
  [178.30329863008936, 187.8515331986062, 197.33683176031778],
  [178.12707909630112, 187.7521343436421, 197.31491043875542],
  [177.9511209919126, 187.65287045363416, 197.29301663049338],
  [177.77542392908177, 187.55374134532534, 197.2711503009999],
  [177.59998752054204, 187.45474683570743, 197.2493114157865],
  [177.4248113796014, 187.35588674202072, 197.22749994040817],
  [177.24989512014145, 187.2571608817536, 197.20571584046291],
  [177.0752383566166, 187.15856907264242, 197.18395908159206],
  [176.90084070405328, 187.06011113267087, 197.16222962948],
  [176.726701778049, 186.9617868800698, 197.14052744985423],
  [176.55282119477164, 186.86359613331695, 197.1188525084852],
  [176.37919857095835, 186.76553871113643, 197.09720477118637],
  [176.20583352391503, 186.6676144324986, 197.07558420381415],
  [176.0327256715152, 186.5698231166195, 197.0539907722677],
  [175.85987463219942, 186.4721645829607, 197.032424442489],
  [175.68728002497417, 186.37463865122896, 197.01088518046288],
  [175.5149414694112, 186.27724514137577, 196.98937295221677],
  [175.3428585856467, 186.1799838735971, 196.96788772382075],
  [175.1710309943803, 186.0828546683331, 196.94642946138748],
  [174.9994583168744, 185.98585734626772, 196.92499813107216],
  [174.8281401749533, 185.88899172832836, 196.9035936990725],
  [174.6570761910023, 185.79225763568553, 196.88221613162858],
  [174.48626598796687, 185.69565488975272, 196.86086539502296],
  [174.31570918935194, 185.59918331218566, 196.83954145558033],
  [174.14540541922094, 185.50284272488244, 196.8182442796678],
  [173.9753543021951, 185.4066329499829, 196.79697383369466],
  [173.80555546345232, 185.31055380986842, 196.77573008411233],
  [173.6360085287268, 185.21460512716152, 196.75451299741437],
  [173.46671312430792, 185.11878672472554, 196.73332254013636],
  [173.2976688770394, 185.02309842566433, 196.7121586788559],
  [173.12887541431863, 184.92754005332205, 196.69102138019255],
  [172.9603323640957, 184.83211143128258, 196.66991061080773],
  [172.79203935487274, 184.73681238336948, 196.64882633740473],
  [172.623996015703, 184.64164273364537, 196.62776852672863],
  [172.45620197618993, 184.54660230641187, 196.60673714556626],
  [172.28865686648663, 184.45169092620915, 196.5857321607461],
  [172.1213603172948, 184.3569084178156, 196.56475353913828],
  [171.95431195986404, 184.26225460624747, 196.5438012476545],
  [171.78751142599097, 184.16772931675877, 196.52287525324803],
  [171.62095834801855, 184.07333237484062, 196.50197552291357],
  [171.454652358835, 183.9790636062212, 196.48110202368727],
  [171.2885930918734, 183.88492283686517, 196.46025472264665],
  [171.12278018111041, 183.7909098929737, 196.43943358691055],
  [170.9572132610659, 183.6970246009838, 196.41863858363908],
  [170.7918919668018, 183.60326678756817, 196.39786968003355],
  [170.62681593392153, 183.50963627963492, 196.37712684333644],
  [170.46198479856912, 183.41613290432713, 196.35641004083135],
  [170.29739819742827, 183.32275648902257, 196.33571923984297],
  [170.1330557677218, 183.22950686133342, 196.31505440773697],
  [169.96895714721072, 183.13638384910598, 196.29441551191996],
  [169.8051019741934, 183.04338728042023, 196.2738025198395],
  [169.64148988750478, 182.9505169835896, 196.253215398984],
  [169.47812052651568, 182.8577727871607, 196.23265411688266],
  [169.31499353113185, 182.7651545199129, 196.2121186411054],
  [169.1521085417933, 182.67266201085798, 196.1916089392629],
  [168.9894651994735, 182.58029508924, 196.1711249790065],
];
