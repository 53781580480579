/** @jsxImportSource theme-ui */
import { useState } from "react";
import { Box, Image } from "theme-ui";
import TabNavigation from "../../../components/TabNavigation";
import { Switch, Route, useLocation, useHistory, Link } from "react-router-dom";
import { AnimatePresence } from "framer-motion/dist/framer-motion";
import MotionScreen from "../MotionScreen";

import menuImage from "../../../images/Support-Scenario-Menu-B.png";
import homeImage from "../../../images/scenario-b-home.png";
import homeInsightsImage from "../../../images/scenario-b-home-insights.png";
import progressImage from "../../../images/scenario-b-progress.png";
import progressImageExpanded from "../../../images/scenario-b-progress-expanded.png";

import { useDoubleTap } from "use-double-tap";
import { useSwipeable } from "react-swipeable";

const Home = () => {
  const [showInsights, setShowInsights] = useState(false);
  const insightsHandlers = useSwipeable({
    onSwipedRight: () => {
      setShowInsights(false);
    },
  });

  return (
    <MotionScreen>
      <Box sx={{ position: "relative" }}>
        <Image
          src={homeImage}
          sx={{ width: "100%", height: "auto", verticalAlign: "bottom" }}
        />
        <Box
          sx={{
            position: "absolute",
            top: "21%",
            left: "9%",
            width: "82vw",
            height: "43vw",
            background: "rgba(255,0,0,0.0)",
          }}
          onClick={() => setShowInsights(true)}
        />
      </Box>
      <AnimatePresence>
        {showInsights && (
          <MotionScreen
            key="b-insights"
            style={{ position: "fixed", zIndex: 10000 }}
            initial={{ x: "100%" }}
            animate={{ x: "0%" }}
            exit={{ x: "100%" }}
            transition={{ ease: "easeInOut", duration: 0.2 }}
          >
            <Image
              src={homeInsightsImage}
              sx={{ width: "100%", height: "auto", verticalAlign: "bottom" }}
              {...insightsHandlers}
            />
            <Box
              sx={{
                position: "absolute",
                top: "7%",
                left: "9%",
                width: "10vw",
                height: "10vw",
                background: "rgba(255,0,0,0.0)",
              }}
              onClick={() => setShowInsights(false)}
            />
          </MotionScreen>
        )}
      </AnimatePresence>
    </MotionScreen>
  );
};
const Progress = () => {
  const [showExpanded, setShowExpanded] = useState(false);
  const [showInsights, setShowInsights] = useState(false);
  const insightsHandlers = useSwipeable({
    onSwipedRight: () => {
      setShowInsights(false);
    },
  });
  return (
    <MotionScreen>
      <Box sx={{ position: "relative" }}>
        <Image
          src={progressImage}
          sx={{ width: "100%", height: "auto", verticalAlign: "bottom" }}
        />
        <Box
          sx={{
            position: "absolute",
            top: "9%",
            left: "5%",
            width: "20vw",
            height: "10vw",
            background: "rgba(255,0,0,0.0)",
          }}
          onClick={() => setShowExpanded(true)}
        />
        <Box
          sx={{
            position: "absolute",
            top: "34%",
            left: "9%",
            width: "82vw",
            height: "43vw",
            background: "rgba(255,0,0,0.0)",
          }}
          onClick={() => setShowInsights(true)}
        />
      </Box>
      <AnimatePresence>
        {showExpanded && (
          <MotionScreen
            key="a-progress-expanded"
            style={{ position: "fixed", zIndex: 10000 }}
            transition={{ ease: "easeInOut", duration: 0.2 }}
          >
            <Image
              src={progressImageExpanded}
              sx={{ width: "100%", height: "auto", verticalAlign: "bottom" }}
            />
            <Box
              sx={{
                position: "absolute",
                top: "8%",
                left: "9%",
                width: "15vw",
                height: "15vw",
                background: "rgba(255,0,0,0.0)",
              }}
              onClick={() => setShowExpanded(false)}
            />
          </MotionScreen>
        )}
        {showInsights && (
          <MotionScreen
            key="b-insights"
            style={{ position: "fixed", zIndex: 10000 }}
            initial={{ x: "100%" }}
            animate={{ x: "0%" }}
            exit={{ x: "100%" }}
            transition={{ ease: "easeInOut", duration: 0.2 }}
          >
            <Image
              src={homeInsightsImage}
              sx={{ width: "100%", height: "auto", verticalAlign: "bottom" }}
              {...insightsHandlers}
            />
            <Box
              sx={{
                position: "absolute",
                top: "7%",
                left: "9%",
                width: "10vw",
                height: "10vw",
                background: "rgba(255,0,0,0.0)",
              }}
              onClick={() => setShowInsights(false)}
            />
          </MotionScreen>
        )}
      </AnimatePresence>
    </MotionScreen>
  );
};

export const ScenarioBPages = () => {
  const location = useLocation();
  const history = useHistory();

  const plusIconEvents = useDoubleTap(() => {
    history.push("/support/c");
    window.ReactNativeWebView?.postMessage("PLUS_ICON_B");
  });

  return (
    <MotionScreen>
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.key}>
          <Route path="/support/b/home" component={Home} exact />
          <Route path="/support/b/progress" component={Progress} exact />
        </Switch>
      </AnimatePresence>
      <TabNavigation
        key="tab-nav-b"
        rootPath="/support/b"
        exclude={["plan", "support"]}
        plusIconEvents={plusIconEvents}
      />
    </MotionScreen>
  );
};

export const ScenarioBMenu = () => {
  return (
    <MotionScreen>
      <Box>
        <Link to="/support/b/home">
          <Image
            src={menuImage}
            sx={{ width: "100%", height: "auto", verticalAlign: "bottom" }}
          />
        </Link>
      </Box>
    </MotionScreen>
  );
};

const ScenarioB = () => {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Switch location={location} key={location.key}>
        <Route path="/support/b" exact component={ScenarioBMenu} />
        <Route path="/support/b" component={ScenarioBPages} />
      </Switch>
    </AnimatePresence>
  );
};

export default ScenarioB;
