/** @jsxImportSource theme-ui */
import { Box } from "theme-ui";

const AbsoluteFill = ({ children, ...rest }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default AbsoluteFill;
