import { motion } from "framer-motion/dist/framer-motion";

const MotionScreen = ({ children, style = {}, ...rest }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      overflow: "auto",
      overscrollBehavior: "contain",
      ...style,
    }}
    {...rest}
  >
    {children}
  </motion.div>
);

export default MotionScreen;
