import landingPageImage from "../images/glide-path-landing.png";
import glidePathPlan from "../images/glide-path-plan.png";
import injectorGroupImage from "../images/injector-group.png";
import bodyImage3 from "../images/body_3.png";
import bodyImage6 from "../images/body_6.png";

const PreloadImages = () => (
  <div style={{ display: "none" }}>
    <img src={landingPageImage} width="1" height="1" border="0" alt="" />
    <img src={injectorGroupImage} width="1" height="1" border="0" alt="" />
    <img src={glidePathPlan} width="1" height="1" border="0" alt="" />
    <img src={bodyImage3} width="1" height="1" border="0" alt="" />
    <img src={bodyImage6} width="1" height="1" border="0" alt="" />
  </div>
);

export default PreloadImages;
