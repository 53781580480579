/** @jsxImportSource theme-ui */
import { Text } from "theme-ui";
import { NavLink } from "react-router-dom";

import styled from "@emotion/styled";

import { ReactComponent as HomeIcon } from "../icons/home.svg";
import { ReactComponent as ProgressIcon } from "../icons/progress.svg";
import { ReactComponent as CirclePlus } from "../icons/circle-plus.svg";
import { ReactComponent as MedicationIcon } from "../icons/medication.svg";
import { ReactComponent as ResourcesIcon } from "../icons/resources.svg";

const TabNavigation = ({
  rootPath = "/",
  plusIconEvents = {},
  exclude = [],
}) => {
  return (
    <>
      <Container>
        <NavLink
          className="nav-item"
          activeClassName="active"
          to={`${rootPath}/home`}
          exact
          style={{
            pointerEvents: exclude.includes("home") ? "none" : "all",
          }}
        >
          <HomeIcon />
          <Text variant="nav">Home</Text>
        </NavLink>
        <NavLink
          className="nav-item"
          activeClassName="active"
          to={`${rootPath}/progress`}
          style={{
            pointerEvents: exclude.includes("progress") ? "none" : "all",
          }}
        >
          <ProgressIcon />
          <Text variant="nav">Progress</Text>
        </NavLink>
        <div className="nav-item" {...plusIconEvents}>
          <CirclePlus />
        </div>
        <NavLink
          className="nav-item"
          activeClassName="active"
          to={`${rootPath}/plan`}
          style={{
            pointerEvents: exclude.includes("plan") ? "none" : "all",
          }}
        >
          <MedicationIcon />
          <Text variant="nav">Plan</Text>
        </NavLink>
        <NavLink
          className="nav-item"
          activeClassName="active"
          to={`${rootPath}/support`}
          style={{
            pointerEvents: exclude.includes("support") ? "none" : "all",
          }}
        >
          <ResourcesIcon />
          <Text variant="nav">Support</Text>
        </NavLink>
      </Container>
    </>
  );
};

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: white;
  padding: 8px 16px;
  padding-bottom: max(16px, env(safe-area-inset-bottom));
  z-index: 3000;

  .nav-item {
    width: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.38);

    &.active {
      color: #1e365e;
      pointer-events: none !important;
    }
  }
`;

export default TabNavigation;
