/** @jsxImportSource theme-ui */
import { useRef, useContext, useState } from "react";
import { Box } from "theme-ui";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import { FullOverlay, InputFlow } from "../components";
import { ProgressChartContainer } from "../components/Charts";

import InfoActionSheet from "../components/InfoActionSheet";
import ViewSelector from "../components/ViewSelector";

import { AppContext } from "../context/AppContext";

const MotionFullOverlay = motion(FullOverlay);

const HomePage = () => {
  const { setShowEditSheet } = useContext(AppContext);
  const controlRef = useRef(null);
  const [showInputs, setShowInputs] = useState(true);
  const [activeViewId, setActiveViewId] = useState("chart");

  return (
    <AnimatePresence>
      <MotionFullOverlay
        key="chart"
        id="chart"
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        sx={{
          height: "100%",
          overflow: "hidden",
          background: "linear-gradient(186deg, #2C3153 0%, #4270D8 100%)",
          zIndex: 2000,
        }}
      >
        <Box className="main" sx={{ flex: "1 1 auto" }}>
          <Box className="chart-container">
            <ProgressChartContainer
              ref={controlRef}
              activeViewId={activeViewId}
            />
          </Box>
          <ViewSelector
            activeId={activeViewId}
            onItemClick={setActiveViewId}
            sx={{
              position: "absolute",
              bottom: "290px",
              left: 0,
              width: "100%",
              borderTop: "1px solid rgba(255,255,255, 0.24)",
            }}
          />
          <InfoActionSheet
            ref={controlRef}
            setShowEditSheet={setShowEditSheet}
          />
        </Box>
      </MotionFullOverlay>
      {showInputs && (
        <MotionFullOverlay
          key="introflow"
          id="introflow"
          exit={{ opacity: 0 }}
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
        >
          <InputFlow onDismiss={() => setShowInputs(false)} />
        </MotionFullOverlay>
      )}
    </AnimatePresence>
  );
};

export default HomePage;
