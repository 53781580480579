/** @jsxImportSource theme-ui */
import {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useMemo,
} from "react";
import { Box, Image, Flex } from "theme-ui";
import {
  VictoryChart,
  VictoryArea,
  VictoryLine,
  VictoryBar,
  VictoryVoronoiContainer,
  VictoryScatter,
  VictoryGroup,
} from "victory";

import { CustomTodayPoint, CustomVictoryTooltip } from "./VictoryCustom";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";

import get from "lodash.get";
import first from "lodash.first";
import last from "lodash.last";

import { GradientDefs } from "./";

import { tooltipLabelStyles, projectedDataLineStyles } from "./styles";

import { isDateActive, getFormattedTooltipDate } from "./utils";

import ProgressDisplay from "./ProgressDisplay";
import ChartButton from "./ChartButton";

import useWindowSize from "../../hooks/useWindowSize";
import bodyImage3 from "../../images/body_3.png";
import bodyImage6 from "../../images/body_6.png";
// import bodyImage9 from "../../images/body_9.png";
import { ReactComponent as RotateIcon } from "../../icons/rotate-icon.svg";

const MotionImage = motion(Image);

const ProgressChart = forwardRef(({ dataset, activeViewId = "chart" }, ref) => {
  const [activeData, setActiveData] = useState({
    min: null,
    mid: null,
    max: null,
  });
  const [activeDateString, setActiveDateString] = useState(
    new Date().toDateString()
  );
  const size = useWindowSize();

  const { loggedData, allData, startDate, endDate, projectedData, dataByDate } =
    dataset;

  let width = size.width || 390;
  let height = size.height ? size.height - 131 : 844; // base height of chart is about 713 (844-131)
  const heightModifier = window.isNative ? 36 : 0;
  let domainPadding = { y: [240, 219 + heightModifier] };

  if (size.height < 680) {
    domainPadding = { y: [230, 184 + heightModifier] };
  }

  const handleActivated = (points) => {
    const target = first(points);
    const newDate = get(target, "x");
    const newDateString = get(target, "dateString");

    if (newDate) {
      setActiveDateString(newDateString);
      const activeData = dataByDate[newDateString];
      setActiveData(activeData);
    }
  };

  useEffect(() => {
    setActiveData({
      min: null,
      max: null,
      mid: get(first(allData), "mid") || 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    controlActiveDate: (date) => {
      const newDateString = date.toDateString();
      setActiveDateString(newDateString);
      const activeData = dataByDate[newDateString];
      setActiveData(activeData);
    },
  }));

  const activeProjected = useMemo(
    () => projectedData.filter((d) => d.dateString === activeDateString),
    [activeDateString, projectedData]
  );
  const activeLogged = useMemo(
    () => loggedData.filter((d) => d.dateString === activeDateString),
    [activeDateString, loggedData]
  );
  const activeDate = useMemo(
    () => new Date(activeDateString),
    [activeDateString]
  );
  const highlightedData = useMemo(
    () => projectedData.filter((d) => d.x <= activeDate),
    [activeDate, projectedData]
  );

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "600px",
        margin: "0 auto",
        position: "relative",
      }}
    >
      <Box
        sx={{
          opacity: activeViewId === "chart" ? 1 : 0,
          transition: "opacity: 0.15s ease-out",
        }}
      >
        <VictoryChart
          width={width}
          height={height}
          scale={{ x: "time", y: "linear" }}
          containerComponent={
            <VictoryVoronoiContainer
              onActivated={handleActivated}
              voronoiDimension={"x"}
              voronoiBlacklist={[
                "GRADIENT_LOWER",
                "GRADIENT_MIDDLE",
                "DATA_COMBO_LINE_ANIMATED_MIN",
                "DATA_COMBO_LINE_ANIMATED_MID",
                "DATA_COMBO_LINE_ANIMATED_MAX",
                "DATA_LOGGED_LINE",
                "DATA_PROJECTED_LINE_MAX",
                "DATA_PROJECTED_LINE_MID",
                "DATA_PROJECTED_LINE_MIN",
                "DATA_LOGGED_SCATTER",
                "DATA_PROJECTED_SCATTER_MIN",
                "DATA_PROJECTED_SCATTER_MID",
                "DATA_PROJECTED_SCATTER_MAX",
                "TOOLTIP_LINE",
                "TOOLTIP_LINE_GROUP",
                "DATA_TODAY_SCATTER",
                "DATA_PROJECTED_SCATTER_END_MIN",
                "DATA_PROJECTED_SCATTER_END_MID",
                "DATA_PROJECTED_SCATTER_END_MAX",
              ]}
            />
          }
          domain={{
            x: [startDate, endDate],
            y: [last(projectedData).min, first(loggedData).y],
          }}
          domainPadding={domainPadding}
          padding={{ left: 0, right: 0, bottom: 0, top: 0 }}
        >
          <VictoryArea
            interpolation={"cardinal"}
            name="GRADIENT_LOWER"
            data={[...loggedData, ...projectedData]}
            style={{
              // data: {
              //   fill: `url(#${chartStyles[stylesId].lowerGradientId})`,
              // },
              data: {
                fill: `url(#lower_gradient_long)`,
                fillOpacity: "0.53",
              },
            }}
            y={"max"}
            y0={0}
          />

          {/* <VictoryArea
              interpolation={"cardinal"}
              name="GRADIENT_MIDDLE"
              data={projectedData}
              y0={"min"}
              y={"max"}
              style={{
                data: {
                  fill: "url(#middle_gradient)",
                  fillOpacity: 0.12,
                  strokeWidth: 0,
                },
              }}
            /> */}

          <VictoryGroup domainPadding={{ x: [5, 5] }} name="TOOLTIP_LINE_GROUP">
            <VictoryBar
              name="TOOLTIP_LINE"
              barWidth={1}
              style={{
                data: {
                  fillOpacity: "0.48",
                  fill: "white",
                },
              }}
              data={activeProjected}
              y={"min"}
              y0={"max"}
            />
          </VictoryGroup>

          <VictoryGroup domainPadding={{ x: [30, 30] }}>
            <VictoryLine
              interpolation={"cardinal"}
              name="DATA_COMBO_TIME_LINE"
              style={{
                data: {
                  strokeWidth: 0,
                },
              }}
              data={[...loggedData, ...projectedData]}
            />
          </VictoryGroup>

          <VictoryLine
            interpolation={"cardinal"}
            name="DATA_LOGGED_LINE"
            domainPadding={{ x: [0, 0] }}
            style={{
              data: {
                stroke: "white",
                strokeWidth: 3,
                strokeLinecap: "round",
                strokeLinejoin: "round",
              },
            }}
            y={"mid"}
            data={loggedData}
          />

          <VictoryLine
            name="DATA_COMBO_LINE_ANIMATED_MIN"
            interpolation={"cardinal"}
            style={{
              data: {
                stroke: "white",
                strokeWidth: 2,
                strokeLinecap: "round",
                strokeLinejoin: "round",
              },
            }}
            data={highlightedData}
            y={"min"}
          />
          <VictoryLine
            name="DATA_COMBO_LINE_ANIMATED_MID"
            interpolation={"cardinal"}
            style={{
              data: {
                stroke: "white",
                strokeWidth: 2,
                strokeLinecap: "round",
                strokeLinejoin: "round",
              },
            }}
            data={highlightedData}
            y={"mid"}
          />
          <VictoryLine
            name="DATA_COMBO_LINE_ANIMATED_MAX"
            interpolation={"cardinal"}
            style={{
              data: {
                stroke: "white",
                strokeWidth: 2,
                strokeLinecap: "round",
                strokeLinejoin: "round",
              },
            }}
            data={highlightedData}
            y={"max"}
          />

          <VictoryLine
            name="DATA_PROJECTED_LINE_MAX"
            interpolation={"cardinal"}
            style={{
              data: projectedDataLineStyles,
            }}
            data={projectedData}
            y={"max"}
          />

          <VictoryLine
            name="DATA_PROJECTED_LINE_MID"
            interpolation={"cardinal"}
            style={{
              data: projectedDataLineStyles,
            }}
            data={projectedData}
            y={"mid"}
          />

          <VictoryLine
            name="DATA_PROJECTED_LINE_MIN"
            interpolation={"cardinal"}
            style={{
              data: projectedDataLineStyles,
            }}
            y={"min"}
            data={projectedData}
          />

          <VictoryScatter
            name="DATA_LOGGED_SCATTER"
            domainPadding={{ x: [5, 5] }}
            size={5}
            style={{
              data: {
                fill: "white",
                fillOpacity: 1,
              },
              labels: tooltipLabelStyles,
            }}
            data={activeLogged}
            labels={({ datum }) => getFormattedTooltipDate({ datum })}
            labelComponent={
              <CustomVictoryTooltip constrainToVisibleArea dy={-15} />
            }
          />

          <VictoryScatter
            name="DATA_PROJECTED_SCATTER_END_MIN"
            domainPadding={{ x: [5, 5] }}
            size={4.5}
            style={{
              data: {
                fill: "#2DA739",
                fillOpacity: 1,
                stroke: "#FFFFFF",
                strokeWidth: 1,
              },
            }}
            data={[last(projectedData)]}
            y={"min"}
          />

          <VictoryScatter
            name="DATA_PROJECTED_SCATTER_END_MID"
            domainPadding={{ x: [5, 5] }}
            size={4.5}
            style={{
              data: {
                fill: "#2DA739",
                fillOpacity: 1,
                stroke: "#FFFFFF",
                strokeWidth: 1,
              },
            }}
            data={[last(projectedData)]}
            y={"mid"}
          />

          <VictoryScatter
            name="DATA_PROJECTED_SCATTER_END_MAX"
            domainPadding={{ x: [5, 5] }}
            size={4.5}
            style={{
              data: {
                fill: "#2DA739",
                fillOpacity: 1,
                stroke: "#FFFFFF",
                strokeWidth: 1,
              },
            }}
            data={[last(projectedData)]}
            y={"max"}
          />

          <VictoryScatter
            name="DATA_PROJECTED_SCATTER_MAX"
            domainPadding={{ x: [5, 5] }}
            size={4.5}
            style={{
              data: {
                fill: "white",
                fillOpacity: 1,
              },
              labels: tooltipLabelStyles,
            }}
            data={activeProjected}
            y={"max"}
            labels={({ datum }) => getFormattedTooltipDate({ datum })}
            labelComponent={
              <CustomVictoryTooltip constrainToVisibleArea dy={-15} />
            }
          />

          <VictoryScatter
            name="DATA_PROJECTED_SCATTER_MID"
            domainPadding={{ x: [5, 5] }}
            size={4.5}
            style={{
              data: {
                fill: "white",
                fillOpacity: 1,
              },
            }}
            data={activeProjected}
            y={"mid"}
          />

          <VictoryScatter
            name="DATA_PROJECTED_SCATTER_MIN"
            domainPadding={{ x: [5, 5] }}
            size={4.5}
            style={{
              data: {
                fill: "white",
                fillOpacity: 1,
              },
              labels: tooltipLabelStyles,
            }}
            data={activeProjected}
            y={"min"}
          />

          {loggedData.length > 0 && (
            <VictoryScatter
              domainPadding={{ x: [5, 5], y: [0, 0] }}
              name="DATA_TODAY_SCATTER"
              size={({ datum }) =>
                isDateActive(activeDateString, datum.dateString) ? 18.5 : 5
              }
              style={{
                data: {
                  fill: ({ datum }) =>
                    isDateActive(activeDateString, datum.dateString)
                      ? "url(#today_gradient)"
                      : "#FFF",
                  fillOpacity: ({ datum }) =>
                    isDateActive(activeDateString, datum.dateString) ? 0.05 : 1,
                },
                labels: tooltipLabelStyles,
              }}
              data={[last(loggedData)]}
              dataComponent={
                <CustomTodayPoint activeDateString={activeDateString} />
              }
            />
          )}
        </VictoryChart>
      </Box>
      <BodyView
        activeViewId={activeViewId}
        onChange={(date) => {
          const newDateString = date.toDateString();
          setActiveDateString(newDateString);
          const activeData = dataByDate[newDateString];
          setActiveData(activeData);
        }}
      />
      <ProgressDisplay activeData={activeData} />
      <GradientDefs />
    </Box>
  );
});

const BodyView = ({ activeViewId, onChange }) => {
  const [activeRangeId, setActiveRangeId] = useState(3);
  const handleClick = (id) => {
    setActiveRangeId(id);
    onChange(addMonths(id));
  };
  useEffect(() => {
    if (activeViewId === "body") {
      onChange(addMonths(activeRangeId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeViewId]);
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: activeViewId === "body" ? 1 : 0,
        transition: "all 0.15s ease-out",
        pointerEvents: activeViewId === "body" ? "all" : "none",
      }}
    >
      <Box>
        <Flex
          sx={{
            maxWidth: "140px",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            mt: "68px",
            ml: "38px",
          }}
        >
          <Box sx={{ position: "relative", width: "122px", height: "368px" }}>
            <AnimatePresence>
              {(!activeRangeId || activeRangeId === 3) && (
                <MotionImage
                  key={"body-3"}
                  src={bodyImage3}
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "122px",
                    height: "auto",
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                />
              )}
              {activeRangeId === 6 && (
                <MotionImage
                  key={"body-6"}
                  src={bodyImage6}
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "122px",
                    height: "auto",
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                />
              )}
            </AnimatePresence>
          </Box>
          <RotateIcon sx={{ mt: "-12px" }} />
        </Flex>
      </Box>
      <Box sx={{ position: "absolute", bottom: "263px", right: "36px" }}>
        <ChartButton
          sx={{ mb: "24px" }}
          active={activeRangeId === 3}
          onClick={() => handleClick(3)}
        >
          3 MO.
        </ChartButton>
        <ChartButton
          sx={{ mb: "24px" }}
          active={activeRangeId === 6}
          onClick={() => handleClick(6)}
        >
          6 MO.
        </ChartButton>
        <ChartButton
        // active={activeRangeId === 9}
        // onClick={() => handleClick(9)}
        >
          9 MO.
        </ChartButton>
      </Box>
    </Box>
  );
};

function addMonths(numOfMonths, date = new Date()) {
  date.setMonth(date.getMonth() + numOfMonths);

  return date;
}

export default ProgressChart;
