/** @jsxImportSource theme-ui */
import { useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import { Box, Image } from "theme-ui";

import { Switch, Route, useLocation, Link, useHistory } from "react-router-dom";
import { AnimatePresence } from "framer-motion/dist/framer-motion";
import StepWizard from "react-step-wizard";
import { useDoubleTap } from "use-double-tap";
import { useSwipeable } from "react-swipeable";

import MotionScreen from "../MotionScreen";
import TabNavigation from "../../../components/TabNavigation";
import menuImage from "../../../images/Support-Scenario-Menu-C.png";
import homeImage from "../../../images/scenario-c-home.png";
import homeImageAltA from "../../../images/scenario-c-home-a.png";
import homeImageAltB from "../../../images/scenario-c-home-b.png";
import homeImageAltC from "../../../images/scenario-c-home-c.png";

const Home = forwardRef((_, controlRef) => {
  const stepperRef = useRef();
  const history = useHistory();

  useImperativeHandle(controlRef, () => ({
    goToNext: () => {
      if (stepperRef.current.totalSteps <= 1) {
        history.push("/support/a");
        return;
      }
      if (stepperRef.current.currentStep === stepperRef.current.totalSteps) {
        history.push("/end");
        return;
      }
      stepperRef.current.nextStep();
    },
  }));

  return (
    <MotionScreen>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: "hidden",
          background: "white",
        }}
      >
        <StepWizard
          instance={(i) => (stepperRef.current = i)}
          className="step-wizard"
          transitions={{
            enterRight: "step-animated fadeInRight",
            enterLeft: "step-animated fadeInLeft",
            exitRight: "step-animated fadeOutRight",
            exitLeft: "step-animated fadeOutLeft",
            intro: "step-animated",
          }}
        >
          <ImageStep imageSrc={homeImage} />
          <ImageStep imageSrc={homeImageAltA} />
          <ImageStep imageSrc={homeImageAltB} />
          <ImageStep imageSrc={homeImageAltC} />
        </StepWizard>
      </Box>
    </MotionScreen>
  );
});

const ImageStep = ({
  isActive,
  currentStep,
  totalSteps,
  imageSrc,
  nextStep,
  previousStep,
}) => {
  const elRef = useRef();
  // const history = useHistory();
  const handlers = useSwipeable({
    onSwipedRight: () => {
      previousStep();
    },
    onSwipedLeft: () => {
      // if (currentStep === totalSteps) {
      //   history.push("/end");
      //   return;
      // }
      nextStep();
    },
  });
  useEffect(() => {
    if (!elRef.current) return;
    if (!isActive) {
      setTimeout(() => elRef.current.scrollTo(0, 0), 500);
    }
  }, [isActive]);
  return (
    <Box
      ref={elRef}
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: "auto",
        overscrollBehavior: "contain",
      }}
    >
      <Box {...handlers}>
        <Image
          src={imageSrc}
          sx={{ width: "100%", height: "auto", verticalAlign: "bottom" }}
        />
      </Box>
    </Box>
  );
};

export const ScenarioCPages = () => {
  const location = useLocation();
  const controlRef = useRef();

  const plusIconEvents = useDoubleTap(() => {
    controlRef.current?.goToNext();
    window.ReactNativeWebView?.postMessage("PLUS_ICON_C");
  });
  return (
    <MotionScreen>
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.key}>
          <Route
            path="/support/c/home"
            render={() => <Home ref={controlRef} />}
            exact
          />
        </Switch>
      </AnimatePresence>
      <TabNavigation
        rootPath="/support/c"
        exclude={["plan", "support", "progress"]}
        plusIconEvents={plusIconEvents}
      />
    </MotionScreen>
  );
};

export const ScenarioCMenu = () => {
  return (
    <MotionScreen>
      <Box>
        <Link to="/support/c/home">
          <Image
            src={menuImage}
            sx={{ width: "100%", height: "auto", verticalAlign: "bottom" }}
          />
        </Link>
      </Box>
    </MotionScreen>
  );
};

const ScenarioC = () => {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Switch location={location} key={location.key}>
        <Route path="/support/c" exact component={ScenarioCMenu} />
        <Route path="/support/c" component={ScenarioCPages} />
      </Switch>
    </AnimatePresence>
  );
};

export default ScenarioC;
