/** @jsxImportSource theme-ui */
import { Switch, Route, Redirect } from "react-router-dom";
import { Global } from "@emotion/react";
import { ThemeProvider } from "theme-ui";

import { PreloadImages } from "./components";
import ConstrainedView from "./components/ContrainedView";

import { EndPage } from "./pages";
import { ScenarioA, ScenarioB, ScenarioC } from "./pages/support";

import { AppContextProvider } from "./context/AppContext";

import { theme, globalStyles } from "./styles";

function App() {
  return (
    <ConstrainedView viewWidth={500}>
      <AppContextProvider>
        <ThemeProvider theme={theme}>
          <PreloadImages />
          <Switch>
            <Route path="/support/a" component={ScenarioA} />
            <Route path="/support/b" component={ScenarioB} />
            <Route path="/support/c" component={ScenarioC} />
            <Route path="/end" component={EndPage} exact />
            <Redirect on="/" to="/support/a" />
          </Switch>
          <Global styles={globalStyles} />
        </ThemeProvider>
      </AppContextProvider>
    </ConstrainedView>
  );
}

export default App;
