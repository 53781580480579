import { normalize } from "polished";
import { css } from "@emotion/react";
const globalStyles = css`
  ${normalize()}
  html, body {
    height: 100%;
  }

  html {
    box-sizing: border-box;
    font-size: 16px;
    background: #44477e;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    -webkit-tap-highlight-color: transparent;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    background: #44477e;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  body::-webkit-scrollbar {
    display: none;
  }

  a {
    text-decoration: none;
  }

  .text-transition_inner div {
    width: 100%;
  }

  .chart-container,
  .chart-container * {
    user-select: none;
  }
  .animated {
    -webkit-animation-duration: 0.8192s;
    animation-duration: 0.8192s;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
  }

  /** fadeInLeft */
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .fadeIn {
    -webkit-animation-duration: 0.8192s;
    animation-duration: 0.8192s;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }

  /** fadeOutLeft */
  @-webkit-keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  .fadeOut {
    -webkit-animation-duration: 0.8192s;
    animation-duration: 0.8192s;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
  }

  .odometer.odometer-auto-theme,
  .odometer.odometer-theme-default {
    line-height: 0.8em;
  }

  .odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
  .odometer.odometer-theme-default.odometer-animating-up
    .odometer-ribbon-inner {
    -webkit-transition: -webkit-transform 0.75s;
    -moz-transition: -moz-transform 0.75s;
    -ms-transition: -ms-transform 0.75s;
    -o-transition: -o-transform 0.75s;
    transition: transform 0.75s;
  }

  .odometer.odometer-auto-theme.odometer-animating-down.odometer-animating
    .odometer-ribbon-inner,
  .odometer.odometer-theme-default.odometer-animating-down.odometer-animating
    .odometer-ribbon-inner {
    -webkit-transition: -webkit-transform 0.75s;
    -moz-transition: -moz-transform 0.75s;
    -ms-transition: -ms-transform 0.75s;
    -o-transition: -o-transform 0.75s;
    transition: transform 0.75s;
  }

  /* .slide {
    height: 100vh;
  } */
  .slide .item.forward-enter,
  .slide .item.back-enter,
  .slide .item.forward-exit,
  .slide .item.back-exit {
    height: 100vh;
  }

  .slide .item.forward-enter-done,
  .slide .item.back-enter-done,
  .slide .item.forward-exit-done,
  .slide .item.back-exit-done {
    overflow: auto;
  }

  .step-wizard {
    height: 100%;

    & > div {
      height: 100%;

      & > div {
        height: 100%;
      }
    }
  }

  .step-animated {
    animation-duration: 0.5s;
    animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
    animation-fill-mode: backwards;
  }

  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }

    to {
      opacity: 1;
      transform: none;
    }
  }

  .fadeInRight {
    animation-name: fadeInRight;
  }

  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }

    to {
      opacity: 1;
      transform: none;
    }
  }

  .fadeInLeft {
    animation-name: fadeInLeft;
  }

  @keyframes fadeOutRight {
    from {
      opacity: 1;
      transform: none;
    }

    to {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
  }

  .fadeOutRight {
    animation-name: fadeOutRight;
  }

  @keyframes fadeOutLeft {
    from {
      opacity: 1;
      transform: none;
    }

    to {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
  }

  .fadeOutLeft {
    animation-name: fadeOutLeft;
  }
`;

export default globalStyles;
