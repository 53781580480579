/** @jsxImportSource theme-ui */
import { Link } from "react-router-dom";
import { Image } from "theme-ui";
import { motion } from "framer-motion/dist/framer-motion";

import blankLilly from "../images/blank-lilly.png";

const EndPage = () => {
  return (
    <motion.div
      style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <Link to="/">
        <Image
          src={blankLilly}
          sx={{ width: "100%", height: "auto", verticalAlign: "bottom" }}
        />
      </Link>
    </motion.div>
  );
};

export default EndPage;
