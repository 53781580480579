import { makeThemeGet } from "./theme-get";

const theme = {
  fonts: {
    inter: "'Inter', sans-serif",
    poppins: "'Poppins', 'Inter', sans-serif",
    lato: "'Lato', 'Inter', sans-serif",
    system:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
  },
  fontSizes: [
    12, // 0
    14, // 1
    16, // 2
    18, // 3
    24, // 4
    26, // 5
    32, // 6
    38, // 7
    64, // 8
  ],
  lineHeights: [
    0, // 0
    1, // 1
    1.25, // 2
    1.33, // 3
    1.42, // 4
    1.5, // 5
    1.625, // 6
    2, // 7
  ],
  fontWeights: { bold: 700, semibold: 600, medium: 500, regular: 400 },
  colors: {
    highlight: "transparent",
    brand: "#2F4255",
    brandSecondary: "#82C3B7",
    heading: "#2F4255",
    headingSecondary: "#D7DDDC",
    text: "#2F4255",
    textSecondary: "#C1C3C5",
    textLight: "rgba(89,89,89,0.6)",
    textBright: "#FFFFFF",
    link: "#FF6565",
    label: "#676C7A",
    labelLight: "#C1C3C5",
    labelDark: "#76808B",
    divider: "#C1C3C5",
    dividerDark: "#76808B",
    background: "#F8F8F8",
    overlay: "rgba(60,60,60,0.40)",
    alert: "#B75319",
    cardBg: "#F5F5F5",
    brandBg: "#E7F1EA",
    imageBg: "#e1e1e1",
    accents: ["#D8E7EB", "#C7E3D0", "#E9E2D5"],
    neutral: {
      gray: {
        light: "#FAFAFA",
        regular: "#F5F5F5",
        medium: "#E6E6E6",
        dark: "#858585",
      },
      black: {
        100: "rgba(0,0,0,1)",
        88: "rgba(0,0,0,0.88)",
        80: "rgba(0,0,0,0.8)",
        64: "rgba(0,0,0,0.64)",
        48: "rgba(0,0,0,0.48)",
        38: "rgba(0,0,0,0.38)",
        20: "rgba(0,0,0,0.20)",
        10: "rgba(0,0,0,0.1)",
        4: "rgba(0,0,0,0.04)",
      },
      white: {
        100: "rgba(255,255,255,1)",
        88: "rgba(255,255,255,0.88)",
        80: "rgba(255,255,255,0.8)",
        64: "rgba(255,255,255,0.64)",
        48: "rgba(255,255,255,0.48)",
        38: "rgba(255,255,255,0.38)",
        20: "rgba(255,255,255,0.20)",
        10: "rgba(255,255,255,0.10)",
        4: "rgba(255,255,255,0.04)",
        gradient: {
          topBottom:
            "linear-gradient(180deg, #FFFFFF 0%, rgba(255,255,255,0.00) 100%)",
          bottomTop:
            "linear-gradient(180deg, rgba(255,255,255,0.00) 0%, #FFFFFF 100%)",
          leftRight:
            "linear-gradient(90deg, #FFFFFF 0%, rgba(255,255,255,0.00) 100%)",
          rightLeft:
            "linear-gradient(270deg, #FFFFFF 0%, rgba(255,255,255,0.00) 100%)",
        },
      },
    },
  },
  shadows: {
    soft: "0 0 3px 0 rgba(0,0,0,0.16)",
    lowest: "0 1px 2px 0 rgba(0,0,0,0.16)",
    lowestFlip: "0 -1px 2px 0 rgba(0,0,0,0.16)",
    low: "0 1px 4px 0 rgba(0,0,0,0.20)",
    lowFlip: "0 -1px 4px 0 rgba(0,0,0,0.20)",
    high: "0 2px 8px 0 rgba(0,0,0,0.24)",
    highFlip: "0 -2px 8px 0 rgba(0,0,0,0.24)",
    highest: "0 2px 14px 0 rgba(0,0,0,0.28)",
    highestFlip: "0 -2px 14px 0 rgba(0,0,0,0.28)",
    elevationLight: "0 0 24px 0 rgba(0,0,0,0.08)",
    elevationMedium: "0 0 64px 0 rgba(0,0,0,0.16)",
  },
  borders: {
    card: "1px solid #F7F7F7",
    primary: "1px solid #1E365E",
    secondary: "1px solid #DADCE1",
  },
  space: [
    "0px", //0
    "2px", //1
    "4px", //2
    "8px", //3
    "12px", //4
    "16px", //5
    "24px", //6
    "32px", //7
    "40px", //8
    "48px", //9
    "56px", //10
    "60px", //11
    "64px", //12
    "72px", //13
    "80px", //14
    "88px", //15
    "96px", //16
    "104px", //17
    "112px", //18
    "120px", //19
    "128px", //20
    "136px",
    "144px",
    "152px",
    "160px",
    "168px",
    "176px",
    "184px",
    "192px",
    "200px",
    "208px",
    "216px",
    "224px",
    "232px",
    "240px",
  ],
  radii: ["0px", "3px", "5px", "8px", "10px", "15px", "50%", "100%"],
  sizes: {},
  letterSpacings: { loose: "0.4px" },
  buttons: {
    primary: {
      background: "brand",
    },
    secondary: {
      variant: "buttons.primary",
    },
    tertiary: {
      variant: "buttons.primary",
    },
  },
  text: {
    default: {
      color: "text",
      fontFamily: "system",
    },
    nav: {
      fontFamily: "inter",
      fontWeight: "semibold",
      fontSize: "10px",
      lineHeight: "1.4",
      color: "inherit",
      mt: "2px",
    },
    heading: {
      fontFamily: "inherit",
      fontWeight: "bold",
      lineHeight: 1,
      color: "heading",
    },
  },
  styles: {
    root: {
      fontFamily: "system",
      fontSize: 2,
      lineHeight: 1,
    },
  },
  forms: {},
};

export const get = makeThemeGet(theme);

export default theme;
