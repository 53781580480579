import { useRef, useEffect } from "react";
import { Curve } from "victory";
import gsap from "gsap";
import DrawSVGPlugin from "gsap/DrawSVGPlugin";
gsap.registerPlugin(DrawSVGPlugin);

const AnimatedPath = (props) => {
  const { d = "", duration = 0.2, ...rest } = props;
  const originalPath = useRef(d);
  const pathRef = useRef(null);
  const invisiblePathRef = useRef(null);

  useEffect(() => {
    const position = DrawSVGPlugin.getPosition(pathRef.current);
    const length = DrawSVGPlugin.getLength(invisiblePathRef.current);
    gsap.fromTo(
      pathRef.current,
      {
        drawSVG: `0 ${position[1]}`,
      },
      { drawSVG: `0 ${length}`, duration }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  return (
    <>
      <path
        d={d}
        style={{ fill: "none", stroke: "none" }}
        ref={invisiblePathRef}
      />
      <path {...rest} d={originalPath.current} ref={pathRef} />
    </>
  );
};

const AnimatedCurve = ({ duration, ...rest }) => {
  return (
    <Curve {...rest} pathComponent={<AnimatedPath duration={duration} />} />
  );
};

export default AnimatedCurve;
