/** @jsxImportSource theme-ui */
import { Box } from "theme-ui";

const AbsoluteBox = ({ children, ...rest }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default AbsoluteBox;
