/** @jsxImportSource theme-ui */
import { Flex, Box } from "theme-ui";
import styled from "@emotion/styled";
import { sx, get } from "../styles";
import { ReactComponent as CloseIcon } from "../icons/close.svg";
import { ReactComponent as BackIcon } from "../icons/chevron-left.svg";
import { ReactComponent as CircleClose } from "../icons/circle-close.svg";
import { ReactComponent as SwipeBar } from "../icons/swipe-bar.svg";

const ActionSheetOverlay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: ${get("colors.neutral.black.10")};

  ${sx}
`;

const ActionSheetInner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: calc(100% - ${get("space.5")});
  border-top-left-radius: ${get("radii.3")};
  border-top-right-radius: ${get("radii.3")};
  box-shadow: ${get("shadows.highest")};
  z-index: 1001;
  background: white;
  overflow: hidden;
  padding-bottom: max(
    16px,
    calc(env(safe-area-inset-bottom) + ${get("space.3")})
  );

  ${sx}
`;

const ActionSheetHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: ${get("space.7")};
  pointer-events: none;
  ${sx}
`;

const ActionSheetFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding-bottom: max(
    16px,
    calc(env(safe-area-inset-bottom) + ${get("space.3")})
  );
  ${sx}
`;

const ActionSheetContent = styled.div`
  position: relative;
  z-index: 500;
`;

const ActionSheetBar = ({ dragHandlers = {}, ...rest }) => (
  <Flex
    sx={{ width: "100%", py: 5, px: 0, justifyContent: "center" }}
    {...dragHandlers}
    {...rest}
  >
    <SwipeBar />
  </Flex>
);

const ActionSheetFooterClose = ({ ...rest }) => (
  <Flex sx={{ width: "100%", pt: 3, justifyContent: "center" }} {...rest}>
    <CircleClose />
  </Flex>
);

const ActionSheetClose = ({ ...rest }) => (
  <Box
    sx={{ position: "absolute", top: 0, right: 0, py: 8, px: 5, zIndex: 1000 }}
    {...rest}
  >
    <CloseIcon />
  </Box>
);

const ActionSheetBack = ({ ...rest }) => (
  <Box
    sx={{ position: "absolute", top: 0, left: 0, py: 8, px: 5, zIndex: 1000 }}
    {...rest}
  >
    <BackIcon />
  </Box>
);

const ActionSheet = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
`;

export default Object.assign(ActionSheet, {
  Overlay: ActionSheetOverlay,
  Inner: ActionSheetInner,
  Content: ActionSheetContent,
  Header: ActionSheetHeader,
  Footer: ActionSheetFooter,
  FooterClose: ActionSheetFooterClose,
  Back: ActionSheetBack,
  Close: ActionSheetClose,
  Bar: ActionSheetBar,
});
