import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";

const useAuth = () => {
  const [state, setState] = useState({
    user: null,
    isSignedIn: false,
  });

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setState({ user, isSignedIn: true });
      })
      .catch(() => Auth.federatedSignIn({ provider: "LillySSO" }));
  }, []);

  return {
    ...state,
  };
};

export default useAuth;
