/** @jsxImportSource theme-ui */
import {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from "react";
import { Box, Input, Flex, Text } from "theme-ui";
import styled from "@emotion/styled";

export const NumberInput = styled(Input)`
  display: block;
  height: 96px;
  font-family: "Poppins", sans-serif;
  padding: 20px 24px;
  font-size: 48px;
  color: #254059;
  outline: 0;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  font-weight: 600;
  transition: all 0.15s ease-out;

  &::placeholder {
    color: #c7c7c7;
    font-weight: normal;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    background: red;
  }

  &:focus {
    background: white;
    box-shadow: 0 8px 36px 0 rgba(0, 0, 0, 0.06);
  }

  &:not(:placeholder-shown) {
    background: white;
  }
`;

const NumberInputContainer = forwardRef(
  (
    { inputId, label, max, min, defaultValue, onUpdate = () => {}, ...rest },
    controlRef
  ) => {
    const inputRef = useRef();
    const [value, setValue] = useState("");
    const [focused, setFocused] = useState(false);

    const handleChange = (e) => {
      if (e.target.value === "") {
        setValue("");
        return;
      }
      let value = +e.target.value;
      if (value > max) {
        value = max;
      }
      setValue(`${value}`);
    };

    const handleFocus = () => setFocused(true);
    const handleBlur = useCallback(() => {
      if (
        inputRef.current?.value === "" &&
        (defaultValue !== undefined || defaultValue == null)
      ) {
        setValue(defaultValue);
        setFocused(false);
        return;
      }
      if (
        min &&
        inputRef.current?.value !== "" &&
        +inputRef.current?.value < min
      ) {
        setValue(min);
      }
      setFocused(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputRef.current]);

    useEffect(() => {
      inputRef.current.addEventListener("focus", handleFocus, false);
      inputRef.current.addEventListener("blur", handleBlur, false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (value === "") return;
      onUpdate(inputId, value);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useImperativeHandle(controlRef, () => ({
      controlValue: (value) => {
        setValue(value);
      },
    }));

    return (
      <Box sx={{ position: "relative" }}>
        <NumberInput
          {...rest}
          ref={inputRef}
          value={value}
          onChange={handleChange}
        />
        <Flex
          sx={{
            position: "absolute",
            right: 0,
            top: "0",
            bottom: 0,
            height: "96px",
            mr: "16px",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Text
            sx={{
              color: value !== "" || focused ? "#3CB949" : "#254059",
              fontFamily: "poppins",
              fontSize: "12px",
              textTransform: "uppercase",
              lineHeight: 1.5,
              transition: "color 0.15s ease-out",
            }}
          >
            {label}
          </Text>
        </Flex>
      </Box>
    );
  }
);

export default NumberInputContainer;
