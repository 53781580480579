/** @jsxImportSource theme-ui */
import { Box, Flex } from "theme-ui";
import Odometer from "react-odometerjs";
import "odometer/themes/odometer-theme-default.css";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
const MotionFlex = motion(Flex);

const ProgressDisplay = ({ activeData = {} }) => {
  const { min, mid, max } = activeData;

  const hasMinMax = min && max && min !== max;
  return (
    <Box
      className="progress-display"
      sx={{
        position: "absolute",
        top: window.isNative ? "56px" : "20px",
        right: "40px",
        display: "inline-block",
        textAlign: "right",
        color: "white",
        pointerEvents: "none",
        "@media all and (max-height: 680px)": {
          top: "20px",
        },
      }}
    >
      <Box
        sx={{
          mb: "15px",
          // "@media all and (max-height: 640px)": {
          //   mb: "10px",
          // },
        }}
      >
        <Box
          sx={{
            fontSize: "68px",
            fontWeight: "semibold",
            // "@media all and (max-height: 640px)": {
            //   fontSize: "38px",
            // },
          }}
        >
          {mid && <Odometer value={mid} format="d" duration={1000} />}
        </Box>
        <Box
          sx={{
            fontSize: "13px",
            lineHeight: "18px",
            fontFamily: "poppins",
            // "@media all and (max-height: 640px)": {
            //   fontSize: "12px",
            // },
          }}
        >
          {hasMinMax ? "Potential Weight (lbs.)" : "Current Weight (lbs.)"}
        </Box>
      </Box>
      <AnimatePresence>
        {hasMinMax && (
          <MotionFlex
            key={"minmax-display"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            sx={{ width: "100%", justifyContent: "flex-end" }}
          >
            <Box
              sx={{
                pr: 6,
              }}
            >
              <Box
                sx={{
                  fontSize: "18px",
                  minWidth: "35px",
                  fontWeight: "medium",
                  // "@media all and (max-height: 640px)": {
                  //   fontSize: "16px",
                  // },
                }}
              >
                {Math.floor(max)}
              </Box>
              <Box
                sx={{
                  mt: 1,
                  fontSize: "11px",
                  lineHeight: "14px",
                  opacity: 0.8,
                }}
              >
                Max
              </Box>
            </Box>
            <Box
              sx={{
                flex: "0 0 1px",
                background: "rgba(255,255,255,0.2)",
                height: "auto",
              }}
            ></Box>
            <Box sx={{ pl: 5 }}>
              <Box
                sx={{
                  fontSize: "18px",
                  minWidth: "35px",
                  fontWeight: "medium",
                  // "@media all and (max-height: 640px)": {
                  //   fontSize: "16px",
                  // },
                }}
              >
                {Math.floor(min)}
              </Box>
              <Box
                sx={{
                  mt: 1,
                  fontSize: "11px",
                  lineHeight: "14px",
                  opacity: 0.8,
                }}
              >
                Min
              </Box>
            </Box>
          </MotionFlex>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default ProgressDisplay;
