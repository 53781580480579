/** @jsxImportSource theme-ui */
import { useContext } from "react";
import { Box, Heading, Input, Label, Button } from "theme-ui";
import { motion } from "framer-motion/dist/framer-motion";

import { ActionSheet } from "../components";
import { AppContext } from "../context/AppContext";

const MotionActionSheetInner = motion(ActionSheet.Inner);

const inputs = [
  {
    name: "weight",
    label: "Baseline Weight",
    type: "number",
    validate: (value) => {
      return value;
      //return Math.floor(value);
    },
  },
  {
    name: "height",
    label: "Height",
    type: "number",
    validate: (value) => {
      return value;
    },
  },
  {
    name: "age",
    label: "Age",
    type: "number",
    validate: (value) => {
      return value;
    },
  },
  {
    name: "pulse",
    label: "Baseline Pulse",
    type: "number",
    validate: (value) => {
      return value;
    },
  },
  {
    name: "dose",
    label: "Dose",
    type: "number",
    validate: (value) => {
      return value;
    },
  },
];

const EditActionSheet = ({ onDismiss = () => {} }) => {
  const { state, updateSetting, getDataset } = useContext(AppContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    getDataset("current");
    onDismiss();
  };

  const handleChange = (name, value) => {
    updateSetting(name, value);
  };

  return (
    <MotionActionSheetInner
      sx={{ height: "100%", zIndex: 2000 }}
      variants={variants}
      animate={"animate"}
      exit={"exit"}
      initial={"initial"}
      drag="y"
      dragSnapToOrigin={true}
      onDragEnd={(_, info) => info.offset.y >= 150 && onDismiss()}
      dragConstraints={{ top: 0 }}
      dragElastic={{ top: 0 }}
    >
      <ActionSheet.Bar></ActionSheet.Bar>
      <Box sx={{ px: 5 }}>
        <Heading>Edit Page</Heading>
        <Box as="form" onSubmit={handleSubmit}>
          {inputs.map(({ name, validate, label, type }) => {
            return (
              <Box key={name} sx={{ my: 6 }}>
                <Label htmlFor={name} sx={{ mb: 3 }}>
                  {label}
                </Label>
                <Input
                  id={name}
                  mb={3}
                  step={1}
                  // min={100}
                  // max={500}
                  type={type}
                  name={name}
                  pattern="[0-9]*"
                  inputMode="decimal"
                  value={state.settings[name]}
                  onChange={(e) => {
                    let value = validate(e.target.value);
                    handleChange(name, value);
                  }}
                />
              </Box>
            );
          })}

          <Button variant="primary">Submit</Button>
        </Box>
      </Box>
    </MotionActionSheetInner>
  );
};

const variants = {
  initial: { y: "100%", opacity: 1 },
  animate: {
    y: 0,
    opacity: 1,
    transition: { delay: 0.15, duration: 0.15, ease: "easeOut" },
  },
  exit: {
    y: "100%",
    opacity: 1,
    transition: { duration: 0.15, ease: "easeIn" },
  },
};

export default EditActionSheet;
