import aMenuImage from "../images/Support-Scenario-Menu-A.png";
import bMenuImage from "../images/Support-Scenario-Menu-B.png";
import cMenuImage from "../images/Support-Scenario-Menu-C.png";
import aHomeImage from "../images/scenario-a-home.png";
import bHomeImage from "../images/scenario-b-home.png";
import cHomeImage from "../images/scenario-c-home.png";
import aHomeChecking from "../images/scenario-a-home-checkin.png";
import aHomeTracking from "../images/scenario-a-home-tracking.png";
import aPlan from "../images/scenario-a-plan.png";
import aProgress from "../images/scenario-a-progress.png";
import aProgressExpanded from "../images/scenario-a-progress-expanded.png";
import aSupportResources from "../images/scenario-a-support.png";
import aSupportCommunity from "../images/scenario-a-support-community.png";
import bHomeInsights from "../images/scenario-b-home-insights.png";
import bProgress from "../images/scenario-b-progress.png";
import bProgressExpanded from "../images/scenario-b-progress-expanded.png";
import cHome from "../images/scenario-c-home.png";
import cHomeA from "../images/scenario-c-home-a.png";
import cHomeB from "../images/scenario-c-home-b.png";
import cHomeC from "../images/scenario-c-home-c.png";
import blankLilly from "../images/blank-lilly.png";

const PreloadScenarioImages = () => (
  <div style={{ display: "none" }}>
    <img src={aMenuImage} width="1" height="1" border="0" alt="" />
    <img src={aHomeImage} width="1" height="1" border="0" alt="" />
    <img src={bMenuImage} width="1" height="1" border="0" alt="" />
    <img src={cMenuImage} width="1" height="1" border="0" alt="" />
    <img src={bHomeImage} width="1" height="1" border="0" alt="" />
    <img src={cHomeImage} width="1" height="1" border="0" alt="" />
    <img src={aHomeChecking} width="1" height="1" border="0" alt="" />
    <img src={aHomeTracking} width="1" height="1" border="0" alt="" />
    <img src={aPlan} width="1" height="1" border="0" alt="" />
    <img src={aProgress} width="1" height="1" border="0" alt="" />
    <img src={aProgressExpanded} width="1" height="1" border="0" alt="" />
    <img src={aSupportResources} width="1" height="1" border="0" alt="" />
    <img src={aSupportCommunity} width="1" height="1" border="0" alt="" />
    <img src={bHomeInsights} width="1" height="1" border="0" alt="" />
    <img src={bProgress} width="1" height="1" border="0" alt="" />
    <img src={bProgressExpanded} width="1" height="1" border="0" alt="" />
    <img src={cHome} width="1" height="1" border="0" alt="" />
    <img src={cHomeA} width="1" height="1" border="0" alt="" />
    <img src={cHomeB} width="1" height="1" border="0" alt="" />
    <img src={cHomeC} width="1" height="1" border="0" alt="" />
    <img src={blankLilly} width="1" height="1" border="0" alt="" />
  </div>
);

export default PreloadScenarioImages;
